import React, { useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import './index.scss';
import { auth } from "../../firebaseConfig";
// import logoImg from '../../assets/images/logo.png';
import { getUserDocument } from "../../firebaseConfig";
import { useStore } from '../../store';

function TopHeader(props) {
  
    const {user, isSignedIn} = props;
    const [state, setState] = useStore();
    let history = useHistory();

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user){
                if (['/login', '/register'].indexOf(history.location.pathname) > -1)
                    history.push('/account');
                getUserDocument(user.uid).then(res => {
                    setState({currentUser: res});
                })
            }
            else {
                if (['/account', '/admin/board', '/admin/upload'].indexOf(history.location.pathname) > -1)
                    history.push('/');
            }
        });
    }, [])

    return null

    if(isSignedIn){
        return (
            <div className="navs">
                <div className="nav">
                </div>
                <div className="nav">
                    <h1>{state.currentUser && state.currentUser.firstname} {state.currentUser && state.currentUser.lastname}</h1>
                    <Link to="/login" className="auth-btn" onClick = {() => {auth.signOut()}}>Log out</Link>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="navs">
                <div className="nav">
                    {/*<Link to="/login"><img src={logoImg} alt="logo"/></Link>*/}
                </div>
                <div className="nav">
                    <Link to="/login" className="auth-btn">Log in</Link>
                    {/*<Link to="/register" className="auth-btn">Register</Link>*/}
                </div>
            </div>
        )
    }
}

export default TopHeader;
