import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './index.scss';
import { useStore } from '../../store';
import { auth, firestore, serverTimestamp, getUserDocument } from "../../firebaseConfig";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import moment from 'moment';
import {
    Button,
    Tabs,
    Form,
    Input,
    DatePicker,
    Row,
    Col
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;


function Download(props) {

    const { id } = useParams();
    let history = useHistory();
    const [state, setState] = useStore();
    const {user, openNotification} = props;
    const [downloadKey, setDownloadKey] = useState();
    const [loader, setLoader] = useState(false);
    const [copied, setCopied] = useState(false);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        if (state.currentUser) {
            loadDownloadKey();
            form.setFieldsValue({
                email: state.currentUser.email,
                firstname: state.currentUser.firstname,
                lastname: state.currentUser.lastname,
                birthday: formatDateToMoment(state.currentUser.birthday)
            })
        }
    }, [state])

    const loadDownloadKey = async () => {
        setLoader(true)
        let snapshot = await firestore.collection('keys').where('uid', '==', state.currentUser.uid).get();
        if (!snapshot.empty) {
            snapshot.docs.forEach(doc => {
                setDownloadKey(doc.data())
            });
        };
        setLoader(false)
    }

    const onSubmitHandler = async (values) => {
        setLoader(true)
        await firestore.collection('users').doc(state.currentUser.uid).update({
            firstname: values.firstname,
            lastname: values.lastname,
            birthday: values.birthday.format('MM/DD/YYYY')
        });
        let resUser = await getUserDocument(state.currentUser.uid)
        setState({currentUser: resUser})
        let kSnapshot = await firestore.collection('keys').where('uid', '==', state.currentUser.uid).get();
        if (!kSnapshot.empty) {
            kSnapshot.docs.forEach(doc => {
                firestore.collection('keys').doc(doc.id).update({
                    username: `${values.firstname} ${values.lastname}`
                });
            });
        };
        setEditable(false)
        setLoader(false)
        openNotification(true, 'User info has been saved successfully!');
    };

    const continueToKey = () => {
        window.open(downloadKey.value, "_blank")
    }

    const getMyDownloadKeyHandler = async () => {
        setLoader(true)
        let snapshot = await firestore.collection('keys').where('uid', '==', '').limit(1).get();
        if (!snapshot.empty) {
            snapshot.docs.forEach(async doc => {
                let keyData = {
                    uid: state.currentUser.uid,
                    username: `${state.currentUser.firstname} ${state.currentUser.lastname}`,
                    redeemedAt: serverTimestamp
                }
                await firestore.collection('keys').doc(doc.id).update(keyData);
                let kSnapshot = await firestore.collection('keys').where('uid', '==', state.currentUser.uid).get();
                if (!kSnapshot.empty) {
                    kSnapshot.docs.forEach(doc => {
                        setDownloadKey(doc.data())
                    });
                };
                openNotification(true, 'You have redeemed download key successfully!');
                setLoader(false)
            });
        }
        else {
            openNotification(false, 'There is currently no active key. Contact with the adminatrator, please.');
            setLoader(false)
        }
    }

    const formatDateToShow = (date) => {
        if (date === '' || date == null)
          return ''
        else
          return moment(date.seconds * 1000).format("MM/DD/YYYY HH:mm:ss");
    }

    const formatDateToMoment = (date) => {
        return moment(date, 'MM/DD/YYYY');
    }
  
    if (state.currentUser)
        return (
            <div className="page-container download-page">
                <div className="page-body d-block">
                    { loader && <div className="loading-bar"><Loader type="TailSpin" color="#7DC763" height={60} width={60} className="m-auto"/></div> }
                    <Tabs tabPosition="top" onChange={() => setEditable(false)}>
                        <TabPane tab="Download" key="0">
                            <h1 className="t-center"> Thanks for joining {state.currentUser.firstname}!</h1>
                            { user && user.emailVerified?
                                downloadKey?
                                    <><div>
                                        <iframe className="instapage-noscrap"
                                                src="//d3mwhxgzltpnyp.cloudfront.net/local-storage/index.html"
                                                name="instapage-local-storage" height="0px" width="0px"
                                                onLoad={(function() { InstapageExternalConversion(22227862); })()}
                                                style={{display: "block"}}/>
                                        {fbq('trackCustom', 'RedeemDownloadKey')}
                                        <p>(Step 2) Click the button below to download Metaterra.</p>
                                        <CopyToClipboard text={ downloadKey.value }>
                                        <div className="d-key">
                                            <p>{ downloadKey.value }</p>
                                            <CopyOutlined onClick={()=> setCopied(true)}/>
                                        </div>
                                        </CopyToClipboard>
                                        <p className="t-copied show">Redeemed at { formatDateToShow(downloadKey.redeemedAt) }</p>
                                        </div>
                                        <Button type="primary" className="primary-btn" onClick={continueToKey}>
                                          Redeem Download via Itch.io
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <p>(Step 1) Click the button below to generate your download key.</p>
                                        <div className="d-key"><p>Your key will be here.</p></div>
                                    <Button type="primary" className="primary-btn" onClick={getMyDownloadKeyHandler}>
                                      Get My Download Key
                                    </Button></>
                                :
                                <>
                                    <iframe className="instapage-noscrap" src="//d3mwhxgzltpnyp.cloudfront.net/local-storage/index.html"
                                            name="instapage-local-storage" height="0px" width="0px"
                                            onLoad={(function() { InstapageExternalConversion(22225172); })()} style={{display: "block"}}/>
                                    <p className="t-center">You have to verify your email first.</p>
                                    <Button type="primary" className="primary-btn" onClick={() => history.push('/verify-email')}>
                                        Verify email
                                    </Button>
                                </>
                            }
                            <p className="t-center"><a href="https://discord.gg/37pXezjdK8" target="_blank" rel="noopener noreferrer" >Join our discord server.</a></p>
                            <p> Your download key allows you to access the private alpha version of Metaterra before our wider release.</p>
                            <p> We are currently supporting both Windows and OSX desktops and are using itch.io to distribute our game. Itch.io is a desktop game platform, basically an indie version of Steam.
                                You will need your own itch.io account as well, but if you use their app it
                                will keep Metaterra updated for you.</p>
                            <p> If you run into any issues, want to join the community, or can provide feedback, please <a href="https://discord.gg/37pXezjdK8" target="_blank" rel="noopener noreferrer">join our discord server.</a></p>
                        </TabPane>
                        <TabPane tab="Account" key="1">
                            <Form
                                className="auth-form"
                                name="profile-form"
                                id="profile-form"
                                layout='vertical'
                                form={form}
                                onFinish={onSubmitHandler}
                                requiredMark={false}
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    colon={false}
                                >
                                    <Input type="email" placeholder="Email Address" disabled />
                                </Form.Item>
                                <Form.Item label="Name" style={{ marginBottom: 0 }} colon={false}>
                                    <Form.Item
                                        name="firstname"
                                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                        rules={[{ required: true, message: '**Required Field' }]}
                                    >
                                        <Input placeholder="First" disabled={!editable}/>
                                    </Form.Item>
                                    <Form.Item
                                        name="lastname"
                                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                                        rules={[{ required: true, message: '**Required Field' }]}
                                    >
                                        <Input placeholder="Last" disabled={!editable}/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    label="Birthday"
                                    name="birthday"
                                    colon={false}
                                    rules={[{ required: true, message: '**Required Field' }]}
                                >
                                    <DatePicker format="MM/DD/YYYY" placeholder="MM/DD/YYYY" disabled={!editable}/>
                                </Form.Item>
                                <Form.Item colon={false}>
                                    {editable?
                                        <Button type="primary" key="save" htmlType="submit" className="primary-btn save-btn">Save</Button>
                                        :
                                        <Button type="primary" key="edit" onClick={() => setEditable(true)} className="primary-btn">Edit</Button>
                                    }

                                </Form.Item>
                            </Form>
                        </TabPane>
                        <TabPane tab="Settings" key="2">
                            {state.currentUser && state.currentUser.admin && <>
                                <Button type="primary" className="primary-btn" onClick={() => history.push('/admin/board')}>
                                    Admin Dashboard
                                </Button>
                                <Button type="primary" className="primary-btn" onClick={() => history.push('/admin/upload')}>
                                    Upload Keys
                                </Button>
                            </>}
                            <Button type="primary" className="primary-btn" onClick={() => history.push('/change-password')}>
                                Change Password
                            </Button>
                            <Button type="primary" className="primary-btn logout-btn" onClick={() => history.push('/logout')}>
                                Log out
                            </Button>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    else
        return null
}

export default Download;
