import React, { useEffect, useState } from 'react';
import Spinner from 'react-spinkit';
import { auth } from "../../firebaseConfig";
import { useHistory } from "react-router-dom";
import { 
  Form,
  Input,
  Button
} from 'antd';

function ResetPassword(props) {

  let history = useHistory();
  const [email, setEmail] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [validCode, setValidCode] = useState(null);
  const [verifiedCode, setVerifiedCode] = useState(false);

  useEffect(() => {
    auth
      .verifyPasswordResetCode(props.actionCode)
      .then(email => {
        setEmail(email);
        setValidCode(true);
        setVerifiedCode(true);
      }, error => {
        setError(error.message);
        setValidCode(false);
        setVerifiedCode(true)
      });
  }, [])

  const changePasswordHandler = (values) => {
    if (values.password !== values.confirmPassword) {
      setError('Password does not match.')
    }
    else {
    // Save the new password.
      auth
        .confirmPasswordReset(props.actionCode, values.password)
        .then(() => {
          setSuccess(true)
        }, error => {
          setError(error.message)
        });
    }
  }

  if (!verifiedCode) {
    return <Spinner spinnerName="three-bounce" />;
  } else if (success) {
    return (
      <div className="page-container confirmation-page">
        <div className="page-body d-block">
          <p className="t-center">Password has been changed successfully.</p>
          <Button type="primary" className="primary-btn" onClick={() => history.push('/')}>
            Continue
          </Button>
        </div>
      </div>
    );
  } else if (verifiedCode && validCode) {
    return (
      <div className="page-container auth-page">
        <div className="page-body">
          <Form 
            name="reset-password-form"
            id="reset-password-form"
            className="auth-form"
            layout='vertical'
            onFinish={changePasswordHandler}
            requiredMark={false}
          >
            <Form.Item
              label="New Password"
              name="password"
              colon={false}
              rules={[{ required: true, message: '**Required Field' }]}
            >
              <Input type="password" placeholder="New Password" />
            </Form.Item>
            <Form.Item
              label="Comfirm Password"
              name="confirmPassword"
              colon={false}
              rules={[{ required: true, message: '**Required Field' }]}
            >
              <Input type="password" placeholder="Confirm Password" />
            </Form.Item>
            {error && <Form.Item colon={false}>
            <p className="error">{ error }</p>
            </Form.Item>}
            <Form.Item colon={false}>
              <Button type="primary" className="primary-btn mt-2" htmlType="submit">Reset Password</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  } else if (verifiedCode && !validCode) {
    return (
      <div className="page-container confirmation-page">
        <div className="page-body d-block">
          <p className="t-center">{error}</p>
          <Button type="primary" className="primary-btn" onClick={() => history.push('/')}>
            Continue
          </Button>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
