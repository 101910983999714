import React, { useEffect, useState } from 'react';
import Spinner from 'react-spinkit';
import { auth } from "../../firebaseConfig";
import { useHistory } from "react-router-dom";
import {
    Button
} from 'antd';

function VerifyEmail(props) {

  const history = useHistory();
  const [error, setError] = useState('');
  const [validCode, setValidCode] = useState(null);
  const [verifiedCode, setVerifiedCode] = useState(false);
  
  useEffect(() => {
    auth
      .applyActionCode(props.actionCode)
      .then(() => {
        setValidCode(true);
        setVerifiedCode(true);
      }, error => {
        setError(error.message);
        setValidCode(false);
        setVerifiedCode(true);
      });
  }, [])

  if (!verifiedCode) {
    return <Spinner spinnerName="three-bounce" />;
  } else if (verifiedCode && validCode) {
    window.location.href = '/account'
  } else if (verifiedCode && !validCode) {
    return (
      <div className="page-container confirmation-page">
        <div className="page-body d-block">
          <p className="t-center">{error}</p>
          <Button type="primary" className="primary-btn" onClick={() => history.push('/verify-email')}>
            Continue
          </Button>
        </div>
      </div>
    );
  }  
}

export default VerifyEmail;
