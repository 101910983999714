import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import './index.scss';
import { firestore, auth, serverTimestamp, defaultTimestamp } from "../../../firebaseConfig";
import { useStore } from '../../../store';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Row,
  Col  
} from 'antd';
import { UploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const { TextArea } = Input;

function Admin(props) {

    let history = useHistory();
    const {user, openNotification} = props;
    const [state, setState] = useStore();
    const [error, setError] = useState(null);
    const [newKeys, setNewKeys] = useState('');
    const [loader, setLoader] = useState(false);
    const [ form ] = Form.useForm();

    const onSubmitHandler = async (values) => {
        setLoader(true);
        let successCount = 0;
        let failCount = 0
        const keysPerBatch = 50;
        const keys = values.keys.split('\n');
        let LastBatch = Math.floor(keys.length / keysPerBatch)

        for (var batchN=0; batchN <= LastBatch; batchN++) {
            let batch = firestore.batch();

            let startKeyIdx = batchN * keysPerBatch;
            let stopKeyIdx = Math.min((batchN + 1) * keysPerBatch, keys.length);

            for (var i = startKeyIdx; i < stopKeyIdx; i++) {
                var key = keys[i].trim();
                //let kSnapshot = await firestore.collection('keys').where('value', '==', key).get();
                //if (kSnapshot.empty) {
                    batch.set(firestore.collection('keys').doc(), {
                        uid: '',
                        value: key,
                        createdAt: serverTimestamp,
                        redeemedAt: defaultTimestamp
                    });
                    successCount += 1;
                // } else {
                //     failCount += 1;
                //     console.log(key);
                // }
            }
            try {
                await batch.commit();
            } catch (e) {
                console.error(e);
                break;
            }
            if (successCount > 0) {
                openNotification(true, `Uploaded ${successCount} key${successCount > 1 ? 's' : ''} of ${keys.length} successfully!`);
            }
        }

        setLoader(false)
    };

    const uploadFileHandler = async (e) => {
        setLoader(true)
        e.preventDefault()
        try {
            const reader = new FileReader()
            reader.onload = async (e) => {
                const text = (e.target.result)
                form.setFieldsValue({keys: text})
                setLoader(false)
            };
            reader.readAsText(e.target.files[0])
        }
        catch {
            setLoader(false)
        }

    }

    if (state.currentUser == null)
        return null

    if (state.currentUser && state.currentUser.admin)
        return (
            <div className="page-container auth-page">
                <div className="page-body">
                    {loader && <div className="loading-bar"><Loader type="TailSpin" color="#7DC763" height={60} width={60} className="m-auto"/></div>}
                    <Form
                        name="upload-form"
                        id="upload-form"
                        className="auth-form"
                        layout='vertical'
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        form={form}
                    >
                        <ArrowLeftOutlined onClick={() => history.goBack()}/>
                        <h1 className="t-center">Upload Keys</h1>
                        <Form.Item name="upload" colon={false} className="mb-2 t-center">
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <UploadOutlined /> Choose key file
                            </label>
                            <Input type="file" id="file-upload" onChange={uploadFileHandler} />
                        </Form.Item>
                        <Form.Item label="Keys" name="keys" colon={false} rules={[{ required: true, message: '**Required Field' }]}>
                            <TextArea rows="6" allowClear />
                        </Form.Item>
                        {error && <Form.Item colon={false}>
                        <p className="error">{ error }</p>
                        </Form.Item>}
                        <Form.Item colon={false}>
                            <Button type="primary" htmlType="submit" className="primary-btn mt-2">Upload</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    else {
        return (
            <div className="page-container auth-page">
                <div className="page-body">
                    <p>You don't have access to this page.</p>
                </div>
            </div>
        )
    }
}

export default Admin;
