import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import './index.scss';
import { firestore } from "../../../firebaseConfig";
import { useStore } from '../../../store';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Table,
  Popconfirm
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

function Board(props) {

    let history = useHistory();
    const { openNotification} = props;
    const [state, setState] = useStore();
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [ form ] = Form.useForm();

    useEffect(() => {
        getData('init');
    }, [])

    const getData = async (type) => {
        setLoader(true);
        let kSnapshot = await firestore.collection('keys').orderBy("redeemedAt", "desc").orderBy("createdAt", "desc").get();
        if (!kSnapshot.empty){
          let data = [];
          kSnapshot.docs.forEach(doc => {
            let dat = doc.data();
            data.push({
                id: doc.id,
                uid: dat.user,
                username: dat.username,
                key: dat.value,
                createdAt: formatDate(dat.createdAt),
                redeemedAt: dat.redeemedAt.seconds == 18000 ? '' : formatDate(dat.redeemedAt),
            });
          });
          setData(data);
        };
        setLoader(false);
    }

    const cancel = () => {
    };

    const formatDate = (date) => {
        if (date === '' || date == null)
            return ''
        else
            return moment(date.seconds * 1000).format("MM/DD/YYYY HH:mm:ss");
    }

    const deleteKey = async (record) => {
        await firestore.collection('keys').doc(record.id).delete();
        openNotification(true, `Deleted the key successfully!`);
        getData('init');
    }

    const columns = [
        {
          title: 'Key',
          dataIndex: 'key',
          width: '44%',
        },
        {
          title: 'User',
          dataIndex: 'username',
          width: '20%',
        },
        {
          title: 'Redeemed At',
          dataIndex: 'redeemedAt',
          width: '16%',
        },
        {
          title: 'Created At',
          dataIndex: 'createdAt',
          width: '16%',      
        },
        {
          title: '',
          dataIndex: 'status',
          width: '4%',
          render: (_, record) => {
            return (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => deleteKey(record)}
                  onVisibleChange={() => console.log('visible change')}
                >
                    <DeleteOutlined />
                </Popconfirm>
            );
          },
        }
      ];

    const onSubmitHandler = async (values) => {
    };

    if (state.currentUser == null)
        return null

    if (state.currentUser && state.currentUser.admin)
        return (
            <div className="page-container admin-board">
                <div className="page-body">
                    {loader && <div className="loading-bar"><Loader type="TailSpin" color="#7DC763" height={60} width={60} className="m-auto"/></div>}
                    <Form
                        name="list-form"
                        id="list-form"
                        className="auth-form"
                        layout='vertical'
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        form={form}
                    >   
                        <div className="d-flex titles">
                            <h1>Keys Management</h1>
                            <div>
                                <Button type="primary" className="primary-btn" onClick={() => history.push('/admin/upload')}>
                                    Upload
                                </Button>
                                <Button type="primary" className="primary-btn ml-2" onClick={() => history.push('/account')}>
                                    Account
                                </Button>
                            </div>
                        </div>
                        <Table
                            bordered
                            dataSource={data}
                            columns={columns}
                            pagination={{
                                onChange: cancel,
                            }}
                        />
                    </Form>
                </div>
            </div>
        )
    else {
        return (
            <div className="page-container auth-page">
                <div className="page-body">
                    <p>You don't have access to this page.</p>
                </div>
            </div>
        )
    }
}

export default Board;
