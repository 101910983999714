import React from 'react';
// import RecoverEmail from './RecoverEmail';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';
import { useHistory } from "react-router-dom";
import {
  Button
} from 'antd';

function Action(props) {

  let history = useHistory();

  const params = new URLSearchParams(props.location.search);  
  const mode = params.get('mode');
  const actionCode = params.get('oobCode');
  let continueUrl = params.get('continueUrl');
  if (continueUrl)
    continueUrl = continueUrl.split('.com')[1]
  else
    continueUrl = ''

  // Handle the user management action.
  switch (mode) {
    // case 'recoverEmail':
    //   // Display reset password handler and UI.
    //   return <RecoverEmail actionCode={actionCode} />;
    case 'resetPassword':
      // Display email recovery handler and UI.
      return <ResetPassword actionCode={actionCode} />;
    case 'verifyEmail':
      // Display email verification handler and UI.
      return <VerifyEmail actionCode={actionCode} continueUrl={continueUrl} />;
    default:
      // Error: invalid mode.
      return (
        <div className="page-container confirmation-page">
          <div className="page-body d-block">
            <p className="t-center">The selected page mode is invalid.</p>
            <Button type="primary" className="primary-btn" onClick={() => history.push(`/`)}>
              Continue
            </Button>
          </div>
        </div>
      );
  }
}

export default Action;
