import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { auth } from "../../firebaseConfig";

function Logout(props) {
  
    let history = useHistory();

    useEffect(() => {
        auth.signOut()
        history.push('/login');
    });

  return null
}

export default Logout;
