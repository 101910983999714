import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import './index.scss';
import {  
  Button  
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

function EmailConfirmation(props) {

    const {user, openNotification} = props;
    let history = useHistory();

    useEffect(() => {
        if (user && !user.emailVerified)
            user.sendEmailVerification({
                url: `https://metaterra-4fa0e.firebaseapp.com/account`,
            }).then(function() {
            }).catch(function(vError) {
                console.log('email verification request: ', vError)
            });
    }, [user])

    const reSendEmailHandler = () => {
        user.sendEmailVerification({
            url: `https://metaterra-4fa0e.firebaseapp.com/account`,
        }).then(function() {
            openNotification(true, 'Resent a verification email successfully.')
        }).catch(function(vError) {
            openNotification(false, vError.message)
        });
    }

    if (user)
    return (

        <div className="page-container confirmation-page">
            <div className="page-body d-block">
                <div>
                    <ArrowLeftOutlined onClick={() => history.goBack()}/>
                </div>
                <h1 className="t-center">Verify your Email</h1>
                { user.emailVerified?
                    <><p className="t-center">Your have been already verified.</p>
                    <Button type="primary" className="primary-btn" onClick={() => history.push(`/account`)}>
                      Continue
                    </Button></>
                    :
                    <><p className="t-center">We have sent an email to { user.email }. Check your mailbox, please.</p>
                    <Button type="primary" className="primary-btn" onClick={reSendEmailHandler}>
                      Resend
                    </Button></>
                }
            </div>
        </div>
    )
    else
        return null
}

export default EmailConfirmation;
