import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

export const firebaseConfig = {
    apiKey: "AIzaSyAEoFPYwbiBZ-kySdrAJ2Bo4YfK5CQ8RxY",
    authDomain: "metaterra-4fa0e.firebaseapp.com",
    projectId: "metaterra-4fa0e",
    storageBucket: "metaterra-4fa0e.appspot.com",
    messagingSenderId: "313496285683",
    appId: "1:313496285683:web:097326cfe2df4ba316a286",
    measurementId: "G-BKRRH8BMBZ"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const database = firebase.database();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
export const defaultTimestamp = firebase.firestore.Timestamp.fromDate(new Date('01/01/1970')).toDate();

export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();

    if (!snapshot.exists) {
        const { email, uid } = user;
        try {
            await userRef.set({
                uid,
                email,
                ...additionalData
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
    return getUserDocument(user.uid);
};

export const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`users/${uid}`).get();
        return {
            uid,
            ...userDocument.data()
            };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};
