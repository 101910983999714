import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import './index.scss';
import { auth, getUserDocument } from "../../firebaseConfig";
import { useStore } from '../../store';
import {
  Form,
  Input,
  Button,
  Row,
  Col
} from 'antd';

function Login(props) {

    let history = useHistory();
    const [state, setState] = useStore();
    const [error, setError] = useState(null);

    const onSubmitHandler = async (values) => {
        auth.signInWithEmailAndPassword(values.email, values.password).then(res => {
            getUserDocument(res.user.uid).then(curUser => {
                setState({currentUser: curUser});
                history.push(`/account`);
            });
        }).catch(error => {
            setError(error.message);
        });
    };

    return (
        <div className="page-container auth-page">
            <div className="page-body">
                <Form 
                    name="login-form"
                    id="login-form"
                    className="auth-form"
                    layout='vertical'
                    onFinish={onSubmitHandler}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        colon={false}
                        rules={[{ required: true, message: '**Required Field' }]}
                    >
                        <Input type="email" placeholder="Email Address" />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        colon={false}
                        rules={[{ required: true, message: '**Required Field' }]}
                    >
                        <Input type="password" placeholder="Password" />
                    </Form.Item>
                    {error && <Form.Item colon={false}>
                    <p className="error">{ error }</p>
                    </Form.Item>}
                    <Form.Item colon={false}>
                        <Button type="primary" className="primary-btn mt-2" htmlType="submit">Sign in</Button>
                    </Form.Item>
                    <Row>
                        <Col span={12} className="m-auto mt-2 t-center">
                            <Link to="/change-password">Forgot Password?</Link>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col span={12} className="m-auto">
                            <Button type="primary" onClick={() => history.push('/register')} className="switch-btn primary-btn">Register</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default Login;
