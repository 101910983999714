import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import './index.scss';
import { auth, generateUserDocument } from "../../firebaseConfig";
import { useStore } from '../../store';
import {
    Form,
    Input,
    Button,
    DatePicker,
} from 'antd';

function MTSignupForm(props) {

    let history = useHistory();
    const [state, setState] = useStore();
    const [error, setError] = useState(null);
    const [registerForm] = Form.useForm();
    const [urlParams, setUrlParams] = useState({});

    React.useEffect(() => {
        let data = {}
        // Fill in the hidden fields with url params if they exist.
        data.utm_campaign = getParameterByName('utm_campaign');
        data.utm_content = getParameterByName('utm_content');
        const referrer = getParameterByName('register_referrer');
        data.register_referrer = referrer ? referrer : window.location.href;
        data.utm_source = getParameterByName('utm_source');
        data.utm_medium = getParameterByName('utm_medium');
        data.sessionId = getParameterByName('userId');

        setUrlParams(data);
    }, []);

    // React.useEffect(() => {
    //     console.log("urlParams", urlParams);
    // }, [urlParams])

    const onSubmitHandler = async (values) => {
        try {
            const {user} = await auth.createUserWithEmailAndPassword(values.email, values.password);
            setState({currentUser: user});

            await generateUserDocument(user, {
                firstname: values.firstname,
                lastname: values.lastname,
                birthday: values.birthday.format('MM/DD/YYYY'),
                utm_campaign: urlParams.utm_campaign,
                utm_source: urlParams.utm_source,
                utm_medium: urlParams.utm_medium,
                utm_content: urlParams.utm_content,
            });
            ga('send', {
                hitType: 'event',
                eventCategory: 'Metaterra-Registration',
                eventAction: 'verify-email-sent'
            });
            fbq('trackCustom', 'Register');
            history.push('/verify-email');
        }
        catch (error) {
            setError(error.message);
        }
    };

    const getParameterByName = function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    return (
        <Form
            className="auth-form"
            name="register-form"
            id="register-form"
            layout='vertical'
            form={registerForm}
            onFinish={onSubmitHandler}
            requiredMark={false}
        >
            <Form.Item
                label="Email"
                name="email"
                colon={false}
                rules={[{ required: true, message: '**Required Field' }]}
            >
                <Input type="email" placeholder="Email Address" />
            </Form.Item>
            <Form.Item label="Name" style={{ marginBottom: 0 }} colon={false}>
                <Form.Item
                    name="firstname"
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    rules={[{ required: true, message: '**Required Field' }]}
                >
                    <Input placeholder="First" />
                </Form.Item>
                <Form.Item
                    name="lastname"
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                    rules={[{ required: true, message: '**Required Field' }]}
                >
                    <Input placeholder="Last" />
                </Form.Item>
            </Form.Item>
            <Form.Item
                label="Birthday"
                name="birthday"
                colon={false}
                rules={[{ required: true, message: '**Required Field' }]}
            >
                <DatePicker format="MM/DD/YYYY" placeholder="MM/DD/YYYY" />
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                colon={false}
                rules={[{ required: true, message: '**Required Field' }]}
            >
                <Input type="password" placeholder="Password" />
            </Form.Item>

            {error && <Form.Item colon={false}>
                <p className="error">{ error }</p>
            </Form.Item>}
            <Form.Item colon={false}>
                <Button type="primary" htmlType="submit" className="primary-btn mt-2">Sign up</Button>
            </Form.Item>
            <Form.Item colon={false}>
                <p className="chk-txt">By signing up, you agree to our <a href="https://fable-studio.com/legal/">Terms of Service and Privacy Policy.</a></p>
            </Form.Item>
        </Form>
    )
}

export default MTSignupForm;
