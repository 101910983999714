import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import {
  Form,
  Input,
  Button,
  Row,
  Col
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

function ChangePassword(props) {

    const {user, openNotification} = props;
    let history = useHistory();

    const changePasswordHandler = (values) => {
        auth.sendPasswordResetEmail(values.email, {
          url: 'https://metaterra-4fa0e.firebaseapp.com/login',
          handleCodeInApp: false
        }).then(function(res) {
          openNotification(true, 'Reset Password request sent successfully!');
        }).catch(function(error) {
          openNotification(false, error.message);
        });
    }

    return (
        <div className="page-container auth-page">
            <div className="page-body">
                <Form 
                    name="change-password-form"
                    id="change-password-form"
                    className="auth-form"
                    layout='vertical'
                    onFinish={changePasswordHandler}
                    requiredMark={false}
                >
                    <ArrowLeftOutlined onClick={() => history.goBack()}/>
                    <h1 className="t-center">Change Password</h1>
                    <Form.Item
                        label="Email"
                        name="email"
                        colon={false}
                        rules={[{ required: true, message: '**Required Field' }]}
                    >
                        <Input type="email" placeholder="Email Address" />
                    </Form.Item>
                    <Form.Item colon={false}>
                        <Button type="primary" className="primary-btn mt-2" htmlType="submit">Send a Request</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ChangePassword;
