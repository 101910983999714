import React, { useEffect, useState } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import Download from "./pages/Download/";
import EmailConfirmation from "./pages/EmailConfirmation/";
import Login from "./pages/Login/";
import Logout from "./pages/Logout/";
import ChangePassword from "./pages/ChangePassword/";
import Register from "./pages/Register/";
import TopHeader from "./pages/TopHeader/";
import BottomFooter from "./pages/Footer/";
import Upload from "./pages/Admin/Upload";
import Board from "./pages/Admin/Board";
import Action from './pages/Action';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './pages/index.scss';
import { BrowserRouter, Redirect, Route, Switch, } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { Layout, notification } from "antd";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
} from "@react-firebase/auth";
import "antd/dist/antd.css";
import { firebaseConfig, auth } from './firebaseConfig';
import { createStore } from './store';

createStore({
    initialState: {
        currentUser: null,
        loader: false
    },
})

const basename = '/';

const history = createBrowserHistory({
    basename
});

const { Header, Content, Footer } = Layout;

function App (props) {

    const openNotification = (status, message) => {
        if (status) {
            notification.success({
                message: message,
                placement: 'topRight',
            });
        }
        else {
            notification.error({
                message: message,
                placement: 'topRight',
            });
        }
    };

  return (
    <BrowserRouter basename={basename} history={history}>
        <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
            <FirebaseAuthConsumer>
                {({ isSignedIn, user, providerId }) => {
                    return (
                        <Layout>
                            {<Header className="header">
                                <TopHeader user={user} isSignedIn={isSignedIn}/>
                            </Header>}
                            <Content className="site-layout">
                                <Switch>
                                    <Route path="/" exact>
                                        <Redirect to="/login" />
                                    </Route>
                                    <Route path="/login" exact
                                        render={(props) => <Login isSignedIn={isSignedIn}/> }
                                    />
                                    <Route path="/register" exact
                                        render={(props) => <Register isSignedIn={isSignedIn} />}
                                    />
                                    <Route path="/logout" exact
                                        render={(props) => <Logout />}
                                    />
                                    <Route path="/change-password" exact
                                        render={(props) =>
                                            <ChangePassword
                                                user={user}
                                                openNotification={openNotification}
                                            />}
                                    />
                                    <Route path="/verify-email" exact
                                        render={(props) =>
                                            <EmailConfirmation
                                                user={user}
                                                isSignedIn={isSignedIn}
                                                openNotification={openNotification}
                                            />}
                                    />
                                    <Route path="/account"
                                        render={(props) =>
                                            <Download
                                                user={user}
                                                isSignedIn={isSignedIn}
                                                openNotification={openNotification}
                                            />}
                                    />
                                    <Route path="/action" component={Action} />
                                    <Route path="/admin" exact>
                                        <Redirect to="/admin/board" />
                                    </Route>
                                    <Route path="/admin/board" exact
                                        render={(props) =>
                                            <Board
                                                user={user}
                                                openNotification={openNotification}
                                            />}
                                    />
                                    <Route path="/admin/upload" exact
                                        render={(props) =>
                                            <Upload
                                                user={user}
                                                openNotification={openNotification}
                                            />}
                                    />
                                </Switch>
                            </Content>
                            {/*<Footer>
                                <BottomFooter />
                            </Footer>*/}
                        </Layout>
                    )
                }}
            </FirebaseAuthConsumer>
        </FirebaseAuthProvider>
    </BrowserRouter>
  );
}

export default App;
