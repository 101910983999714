import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import './index.scss';
import {
  Button,
  Row,
  Col
} from 'antd';
import MTSignupForm from "../../components/Forms/MTSignupForm";

function Register(props) {

    let history = useHistory();

    return (
        <div className="page-container auth-page">
            <div className="page-body">
                <MTSignupForm/>
            </div>
        </div>
    )
}

export default Register;
